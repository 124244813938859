import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { config } from '@fortawesome/fontawesome-svg-core' // 👈
import '@fortawesome/fontawesome-svg-core/styles.css' // 👈
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' // 👈
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { Container, Segment } from "semantic-ui-react"
import "./header.css"
config.autoAddCss = false // 👈

const Header = ({ siteTitle }) => (
  <Segment basic padded textAlign="center" className="header__box">
    <h1 style={{ margin: 0 }}>
      <Link
        to="/"
        style={{
          color: `black`,
          textDecoration: `none`,
        }}
      >
        Andy Kohv
      </Link>
    </h1>
    <p>Senior Software Engineer @ Pipedrive</p>
    <p>
      <a
        className="header-link-ext"
        href="https://github.com/kohv"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faGithub} />
      </a>
      <a
        className="header-link-ext"
        href="mailto:andy....kohv@gmail.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faEnvelope} />
      </a>
      <a
        className="header-link-ext"
        href="https://www.linkedin.com/in/andy-kohv"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faLinkedin} />
      </a>
    </p>
  </Segment>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
